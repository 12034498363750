import { Box, Divider, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { fetchTipOfTheDay, Tip } from "../api/tips.fetcher";
import LoadingIndicator from "../components/LoadingIndicator/LoadingIndicator";
import ImageWithPlaceholder from "../components/ImageWithPlaceholder/ImageWithPlaceholder";

const useStyles = makeStyles({
  typography: {
    textAlign: "center",
  },
  welcomeBanner: {
    backgroundColor: "#121212",
    padding: "16px 16px",
    borderRadius: "14px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
  },
  divider: {
    backgroundColor: "white",
    height: "2px",
    marginTop: "32px !important",
    marginBottom: "32px !important",
    marginLeft: "12% !important",
    marginRight: "12% !important",
  },
  tipContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    paddingLeft: "8px",
    paddingRight: "8px",
  },
  tipBanner: {
    borderRadius: "14px",
  },
  homeContainer: {
    paddingLeft: "8px",
    paddingRight: "8px",
    paddingTop: "16px",
    paddingBottom: "8px",
  },
  tipContent: {
    padding: "8px",
    backgroundColor: "#150028",
    border: "2px solid #660872",
    borderRadius: "16px",
  },
});

const HomePage = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [tip, setTip] = React.useState<Tip>();

  React.useEffect(() => {
    setIsLoading(true);
    fetchTipOfTheDay({
      onSuccess: (data: Tip) => {
        setTip(data);
        setIsLoading(false);
      },
      onError: () => {},
    });
  }, []);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (!tip) {
    return <></>;
  }

  return (
    <Box className={classes.homeContainer}>
      <Box className={classes.welcomeBanner}>
        <Typography variant="h4" className={classes.typography}>
          Welcome to Unicast.ai!
        </Typography>
        <Typography variant="body1" className={classes.typography}>
          Find great opportunities in the Web3 space! ✨
        </Typography>
      </Box>
      <Divider className={classes.divider} />

      <Box className={classes.tipContainer}>
        <Typography variant="h4">Tip of the day</Typography>
        <ImageWithPlaceholder
          src={tip.imageUrl}
          alt={`${tip!.title} banner`}
          imageWidth={1280}
          imageHeight={566}
          className={classes.tipBanner}
        />
        <Box className={classes.tipContent}>
          <Typography
            sx={{
              whiteSpace: "pre-line",
              fontSize: "18px",
            }}
          >
            {tip?.description}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default HomePage;
