import { Box, Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ImageWithPlaceholder from "../ImageWithPlaceholder/ImageWithPlaceholder";
import Markdown from "react-markdown";
import { Opening } from "../../views/AllOpeningsPage/hooks/useFetchOpenings";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles({
  card: {
    borderRadius: "16px",
    backgroundColor: "#121212",
  },
  banner: {
    borderTopLeftRadius: "16px",
    borderTopRightRadius: "16px",
    width: "100%",
  },
  description: {
    padding: "12px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
});

const positionTypeLabel = {
  partTime: "Part Time",
  fullTime: "Full Time",
  contract: "Contract",
  freelance: "Freelance",
  internship: "Internship",
};

const jobLocationLabel = {
  onSite: "On site",
  remote: "Remote",
  onSiteOrRemote: "On site or Remote",
};

function JobCard({ opening }: { opening: Opening }) {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Box
      className={classes.card}
      onClick={() => navigate(`/openings/${opening.id}`)}
    >
      <ImageWithPlaceholder
        src={opening.company.bannerPath}
        alt={opening.company.name}
        className={classes.banner}
        imageWidth={250}
        imageHeight={100}
      />
      <Box className={classes.description}>
        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
          💎 {opening.company.name} is hiring! 💎
        </Typography>
        <Markdown>{opening.company.summary}</Markdown>
        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
          Position: {opening.title}
        </Typography>
        <Typography>
          🕒 {positionTypeLabel[opening.positionType]} - 📍{" "}
          {jobLocationLabel[opening.jobLocation]}
        </Typography>
        <Markdown>{opening.summary}</Markdown>
        <Box className={classes.buttonContainer}>
          <Button
            sx={{
              width: "20%",
              borderRadius: "24px",
              backgroundColor: "#61157D",
              color: "#FFFFFF",
              marginBottom: 2,
              "&:hover": {
                backgroundColor: "#61157D",
              },
              "&:active": {
                backgroundColor: "#61157D",
              },
            }}
          >
            Share
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default JobCard;
