import React from "react";
import { Typography, Alert, Box, Button } from "@mui/material";
import Markdown from "react-markdown";
import { makeStyles } from "@mui/styles";
import { JobOpening, fetchOpening } from "../api/opening.fetcher";
import LoadingIndicator from "../components/LoadingIndicator/LoadingIndicator";
import NotFound from "../components/NotFound/NotFound";
import { useNavigate, useParams } from "react-router-dom";
import ImageWithPlaceholder from "../components/ImageWithPlaceholder/ImageWithPlaceholder";

const useStyles = makeStyles({
  container: {
    paddingTop: "16px",
    paddingLeft: "8px",
    paddingRight: "8px",
    paddingBottom: "16px",
    backgroundColor: "#150028",
  },
  banner: {
    borderRadius: "16px",
  },
  company: {
    padding: "8px",
  },
  opening: {
    paddingLeft: "8px",
    paddingRight: "8px",
    paddingBottom: "4px",
    borderRadius: "24px",
    backgroundColor: "#3B0742",
  },
  companyTitle: {
    textAlign: "center",
    paddingBottom: "24px",
  },
  openingTitle: {
    textAlign: "center",
    paddingTop: "16px",
  },
  content: {
    paddingLeft: "4px",
    paddingRight: "4px",
  },
  actions: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "16px",
    width: "100%",
  },
});

const JobDescriptionPage = () => {
  const classes = useStyles();
  const { openingId } = useParams<{ openingId: string }>();
  const [jobOpening, setJobOpening] = React.useState<JobOpening | null>(null);

  const navigate = useNavigate();

  const applyForOpening = React.useCallback(() => {
    navigate(`/openings/${openingId}/apply`);
  }, [navigate, openingId]);

  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);

  const onSuccess = React.useCallback((data: JobOpening) => {
    setJobOpening(data);
    setLoading(false);
  }, []);

  const onError = () => {
    setError("Failed to load job description.");
    setLoading(false);
  };

  React.useEffect(() => {
    if (!openingId) {
      return;
    }
    fetchOpening({ jobId: openingId, onSuccess, onError });
  }, [openingId, onSuccess]);

  if (loading) {
    return <LoadingIndicator />;
  }

  if (!jobOpening && !loading) {
    return <NotFound />;
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.company}>
        <Box>
          <Typography
            className={classes.companyTitle}
            sx={{ fontWeight: "bold" }}
            variant="h5"
          >
            {jobOpening?.company?.name}
          </Typography>
        </Box>
        <ImageWithPlaceholder
          imageWidth={1280}
          imageHeight={466}
          src={jobOpening!.company.bannerPath}
          alt={jobOpening!.company.name}
          className={classes.banner}
        />
        <Markdown>{jobOpening!.company.summary}</Markdown>
      </Box>
      <Box className={classes.opening}>
        <Typography className={classes.openingTitle} variant="h5">
          {jobOpening!.title}
        </Typography>
        <div className={classes.content}>
          <Markdown>{jobOpening!.description}</Markdown>
        </div>
      </Box>
      <Box className={classes.actions}>
        <Button
          onClick={applyForOpening}
          sx={{
            width: "90%",
            borderRadius: "24px",
            backgroundColor: "#61157D",
            color: "#FFFFFF",
          }}
        >
          Apply now
        </Button>
      </Box>
    </Box>
  );
};

export default JobDescriptionPage;
