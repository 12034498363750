import React from "react";
import {
  OPENING_ROLES,
  OpeningRoleValue,
} from "../../../utils/opening.filters";
import { Drawer, Box, Button } from "@mui/material";
import CollapsibleFilter from "./CollapsibleFilter";
import { makeStyles } from "@mui/styles";
import { omit } from "lodash";

export interface Filters {
  query: string;
  roles: OpeningRoleValue[];
}

export const INITIAL_FILTERS: Filters = {
  query: "",
  roles: [],
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  buttonsContainer: {
    bottom: 0,
    position: "fixed",
    marginLeft: "-16px",
    backgroundColor: "#121212",
    width: "100%",
    color: "white",
    zIndex: 2000,
    padding: "8px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
});

export default function FilterDrawer({
  open,
  onClose,
  filters,
  setFilters,
}: {
  open: boolean;
  onClose: () => void;
  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
}) {
  const [localFilters, setLocalFilters] = React.useState<
    Omit<Filters, "query">
  >(omit(filters, "query"));

  const setSelectedRoles = React.useCallback(
    (selectedRoles: OpeningRoleValue[]) => {
      setLocalFilters((previous) => ({ ...previous, roles: selectedRoles }));
    },
    [setLocalFilters]
  );

  const selectedRoles = React.useMemo(
    () =>
      OPENING_ROLES.filter((opt) => localFilters.roles.includes(opt.value)).map(
        (opt) => opt.value
      ),
    [localFilters.roles]
  );

  const classes = useStyles();

  const onCancel = React.useCallback(() => {
    setLocalFilters(omit(INITIAL_FILTERS, "query"));
    setFilters((previous) => ({ ...INITIAL_FILTERS, query: previous.query }));
    onClose();
  }, [onClose, setFilters]);

  const onConfirm = React.useCallback(() => {
    setFilters((previous) => ({ ...localFilters, query: previous.query }));
    onClose();
  }, [localFilters, onClose, setFilters]);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "100%",
          bgcolor: "#3B0742",
          color: "#FFFFFF",
          padding: "16px",
        },
      }}
    >
      <Box className={classes.container}>
        <CollapsibleFilter
          title={"Role"}
          options={[...OPENING_ROLES]}
          selectedOptions={selectedRoles}
          onChange={(selected) =>
            setSelectedRoles(selected as OpeningRoleValue[])
          }
        />

        <Box className={classes.buttonsContainer}>
          <Button
            onClick={onCancel}
            sx={{
              width: "36%",
              border: "1px solid #61157D",
              borderRadius: "24px",
              color: "#FFFFFF",
            }}
          >
            Reset Filters
          </Button>
          <Button
            onClick={onConfirm}
            sx={{
              width: "36%",
              borderRadius: "24px",
              backgroundColor: "#61157D",
              color: "#FFFFFF",
            }}
          >
            Show jobs
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}
