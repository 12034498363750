import React from "react";
import { useApplicationForm } from "../hooks/form.hook";
import { useTelegram } from "../../../utils/Telegram.Provider";
import { useFileInput } from "../hooks/fileInput.hook";
import {
  TextField,
  Typography,
  FormControlLabel,
  Box,
  Button,
} from "@mui/material";
import { JobOpening } from "../../../api/opening.fetcher";
import ThemedSwitch from "../../../components/Custom/Switch";
import { makeStyles } from "@mui/styles";
import { FileUploadField } from "../../../components/FileUploadField/FileUploadField";

interface ApplicationFormState {
  firstName: string;
  lastName: string;
  email: string;
  xProfile: string;
  portfolio: string;
  comment: string;
}

const useStyles = makeStyles({
  actions: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "32px",
    width: "100%",
  },
  header: {
    padding: "16px",
    paddingBottom: "32px",
    background: "linear-gradient(to right, #952A98, #660872)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
});

const FormFields = ({
  formData,
  handleChange,
  file,
  handleFileChange,
  clearFile,
}: {
  formData: any;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  file: File | null;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  clearFile: () => void;
}) => (
  <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
    <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
      <TextField
        name="firstName"
        label="First Name"
        value={formData.firstName}
        onChange={handleChange}
        inputProps={{ maxLength: 25 }}
        helperText="Max 25 characters"
        error={formData.firstName.length > 25}
        size="small"
        fullWidth
        required
      />
      <TextField
        name="lastName"
        label="Last Name"
        value={formData.lastName}
        onChange={handleChange}
        inputProps={{ maxLength: 25 }}
        helperText="Max 25 characters"
        error={formData.lastName.length > 25}
        size="small"
        fullWidth
        required
      />
    </div>
    <TextField
      name="email"
      label="Email"
      type="email"
      value={formData.email}
      onChange={handleChange}
      inputProps={{ maxLength: 50 }}
      helperText="Max 50 characters"
      error={formData.email.length > 50}
      size="small"
      fullWidth
      required
    />
    <FileUploadField
      file={file}
      handleFileChange={handleFileChange}
      clearFile={clearFile}
    />
    <TextField
      name="xProfile"
      label="X Profile"
      type="url"
      value={formData.xProfile}
      onChange={handleChange}
      inputProps={{ maxLength: 50 }}
      helperText="Max 50 characters"
      error={formData.xProfile.length > 50}
      size="small"
      fullWidth
    />
    <TextField
      name="portfolio"
      label="Github, Linkedin or Portfolio"
      type="url"
      value={formData.portfolio}
      onChange={handleChange}
      inputProps={{ maxLength: 50 }}
      helperText="Max 50 characters"
      error={formData.portfolio.length > 50}
      size="small"
      fullWidth
    />
    <TextField
      name="comment"
      label="Comments"
      multiline
      rows={4}
      value={formData.comment}
      onChange={handleChange}
      inputProps={{ maxLength: 1000 }}
      helperText="Max 1000 characters"
      error={formData.comment.length > 1000}
      fullWidth
    />
  </div>
);

export const ApplicationForm = ({
  jobOpening,
  onSuccess,
  onError,
}: {
  jobOpening: JobOpening;
  onSuccess: () => void;
  onError: () => void;
}) => {
  const { formData, handleChange } = useApplicationForm<ApplicationFormState>({
    firstName: "",
    lastName: "",
    email: "",
    xProfile: "",
    portfolio: "",
    comment: "",
  });

  const { file, handleFileChange, clearFile } = useFileInput();
  const [shareTelegramContact, setShareTelegramContact] =
    React.useState<boolean>(true);
  const [createProfileEnabled, setCreateProfileEnabled] =
    React.useState<boolean>(true);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const telegram = useTelegram();

  const createProfile = React.useCallback((payload: FormData) => {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/api/profiles`, {
      method: "POST",
      body: payload,
    });
  }, []);

  const createApplication = React.useCallback((payload: FormData) => {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/api/applications`, {
      method: "POST",
      body: payload,
    });
  }, []);

  const { applicationPayload, profilePayload } = React.useMemo(() => {
    const applicationPayload = new FormData();
    const profilePayload = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      if (value) {
        applicationPayload.append(key, value);
        if (key !== "email" && key !== "comment") {
          profilePayload.append(key, value);
        }
      }
    });

    applicationPayload.append("openingId", jobOpening.id);
    applicationPayload.append("companyId", jobOpening.companyId);
    if (file) {
      applicationPayload.append("resume", file);
      profilePayload.append("resume", file);
    }

    if (telegram) {
      // const { id, username } = telegram.initDataUnsafe?.user;
      profilePayload.append("telegramId", String("12"));
      if (shareTelegramContact) {
        profilePayload.append("telegramId", String("12"));
        applicationPayload.append("telegramUsername", "Hello");
      }
    }

    return { applicationPayload, profilePayload };
  }, [
    file,
    formData,
    jobOpening.companyId,
    jobOpening.id,
    shareTelegramContact,
    telegram,
  ]);

  const handleSubmit = React.useCallback(() => {
    setIsLoading(true);
    const requests = [createApplication(applicationPayload)];
    if (createProfileEnabled) {
      requests.push(createProfile(profilePayload));
    }
    function handleSuccess() {
      onSuccess();
      setIsLoading(false);
    }

    function handleError() {
      onError();
      setIsLoading(false);
    }

    Promise.all(requests)
      .then(() => {
        handleSuccess();
      })
      .catch((error) => {
        handleError();
      });
  }, [
    applicationPayload,
    createApplication,
    createProfile,
    createProfileEnabled,
    onError,
    onSuccess,
    profilePayload,
  ]);

  const isFormValid = React.useMemo(() => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return (
      !!file &&
      file.type === "application/pdf" &&
      formData.firstName &&
      formData.lastName &&
      formData.email &&
      emailRegex.test(formData.email)
    );
  }, [file, formData]);

  const classes = useStyles();

  return (
    <Box style={{ padding: "8px 2%" }}>
      <Box className={classes.header}>
        <Typography variant="h4" sx={{ fontWeight: "bold" }}>
          {jobOpening.title}
        </Typography>
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold", paddingBottom: "8px" }}
        >
          At {jobOpening.company?.name}
        </Typography>
      </Box>
      <Box>
        <FormFields
          formData={formData}
          handleChange={handleChange}
          file={file}
          handleFileChange={handleFileChange}
          clearFile={clearFile}
        />
        <FormControlLabel
          control={
            <ThemedSwitch
              checked={shareTelegramContact}
              onChange={(e) => setShareTelegramContact(e.target.checked)}
            />
          }
          label="Share my Telegram ID with company"
        />
        <FormControlLabel
          control={
            <ThemedSwitch
              checked={createProfileEnabled}
              onChange={(e) => setCreateProfileEnabled(e.target.checked)}
            />
          }
          label="Save info for future applications"
        />
      </Box>
      <Box className={classes.actions}>
        <Button
          disabled={!isFormValid || isLoading}
          onClick={handleSubmit}
          sx={{
            width: "90%",
            borderRadius: "24px",
            backgroundColor: "#61157D",
            color: "#FFFFFF",
            "&:hover": {
              backgroundColor: "#61157D",
            },
            "&:active": {
              backgroundColor: "#61157D",
            },
            "&.Mui-disabled": {
              backgroundColor: "#A687B0",
              color: "#E5D5ED",
            },
          }}
        >
          Apply now
        </Button>
      </Box>
    </Box>
  );
};
