import React from "react";
import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FileUploadField } from "../../../components/FileUploadField/FileUploadField";
import { useFileInput } from "../../ApplyJobPage/hooks/fileInput.hook";
import { useApplicationForm as useForm } from "../../ApplyJobPage/hooks/form.hook";
import { useTelegram } from "../../../utils/Telegram.Provider";
import { Profile } from "../hooks/useGetProfile";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const useStyles = makeStyles({
  container: {
    padding: "16px",
    paddingBottom: "16px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#121212",
    minHeight: "calc(100vh - 66px)",
  },
  header: {
    padding: "16px",
    paddingBottom: "32px",
    background: "linear-gradient(to right, #952A98, #660872)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  actions: {
    paddingBottom: "8px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

interface ProfileForm {
  firstName: string;
  lastName: string;
  xProfile: string;
  portfolio: string;
  aboutMe: string;
}

interface ProfileEditionFormProps {
  profile?: Profile;
  onCloseEdition: () => void;
}

function ProfileEditionForm({
  profile,
  onCloseEdition,
}: ProfileEditionFormProps) {
  const { formData, handleChange } = useForm<ProfileForm>({
    firstName: profile?.firstName || "",
    lastName: profile?.lastName || "",
    xProfile: profile?.xProfile || "",
    portfolio: profile?.portfolio || "",
    aboutMe: profile?.aboutMe || "",
  });

  const { file, handleFileChange, clearFile } = useFileInput();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const classes = useStyles();
  const telegram = useTelegram();

  const isFormValid = React.useMemo(() => {
    return (
      ((!profile && !!file && file.type === "application/pdf") || !!profile) &&
      formData.firstName &&
      formData.lastName
    );
  }, [file, formData, profile]);

  const endpoint = React.useMemo((): string => {
    const resource = !profile
      ? "api/profiles"
      : `api/profiles/telegram/${telegram?.initDataUnsafe?.user?.id}`;

    return resource;
  }, [profile, telegram]);

  const handleSubmit = React.useCallback(() => {
    setIsLoading(true);
    const payload = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      if (value) payload.append(key, value);
    });

    if (file) payload.append("resume", file);
    if (telegram) {
      const { id } = telegram?.initDataUnsafe?.user;
      payload.append("telegramId", String(id));
    }

    fetch(`${process.env.REACT_APP_SERVER_URL}/${endpoint}`, {
      method: !!profile ? "PUT" : "POST",
      body: payload,
    })
      .then((response) => {
        response.status < 400 ? handleSuccess() : handleError();
      })
      .catch(handleError);

    function handleSuccess() {
      setIsLoading(false);
      onCloseEdition();
    }

    function handleError() {
      setIsLoading(false);
    }
  }, [formData, file, telegram, endpoint, profile, onCloseEdition]);

  return (
    <Box className={classes.container}>
      <Box>
        <IconButton color="inherit" onClick={onCloseEdition}>
          <ArrowBackIcon />
        </IconButton>
        <Box className={classes.header}>
          <Typography variant="h5">Let's build your profile</Typography>
        </Box>
        <ProfileFormFields
          formData={formData}
          handleChange={handleChange}
          file={file}
          handleFileChange={handleFileChange}
          clearFile={clearFile}
          isResumeRequired={!profile}
        />
      </Box>
      <Box className={classes.actions}>
        <Button
          disabled={isLoading || !isFormValid}
          onClick={handleSubmit}
          sx={{
            width: "90%",
            borderRadius: "24px",
            backgroundColor: "#3B0742",
            color: "#FFFFFF",
            "&:hover": {
              backgroundColor: "#3B0742",
            },
            "&:active": {
              backgroundColor: "#3B0742",
            },
            "&.Mui-disabled": {
              backgroundColor: "#A687B0",
              color: "#E5D5ED",
            },
          }}
        >
          Save profile
        </Button>
      </Box>
    </Box>
  );
}

const ProfileFormFields = ({
  formData,
  handleChange,
  file,
  handleFileChange,
  clearFile,
  isResumeRequired,
}: {
  formData: any;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  file: File | null;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  clearFile: () => void;
  isResumeRequired?: boolean;
}) => (
  <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
    <div style={{ display: "flex", flexDirection: "row", gap: "12px" }}>
      <TextField
        name="firstName"
        label="First Name"
        value={formData.firstName}
        onChange={handleChange}
        inputProps={{ maxLength: 25 }}
        error={formData.firstName.length > 25}
        size="small"
        fullWidth
        required
      />
      <TextField
        name="lastName"
        label="Last Name"
        value={formData.lastName}
        onChange={handleChange}
        inputProps={{ maxLength: 25 }}
        error={formData.lastName.length > 25}
        size="small"
        fullWidth
        required
      />
    </div>
    <FileUploadField
      file={file}
      handleFileChange={handleFileChange}
      clearFile={clearFile}
      isResumeRequired={isResumeRequired}
    />
    <TextField
      name="xProfile"
      label="X Profile"
      type="url"
      value={formData.xProfile}
      onChange={handleChange}
      inputProps={{ maxLength: 50 }}
      error={formData.xProfile.length > 50}
      size="small"
      fullWidth
    />
    <TextField
      name="portfolio"
      label="Github, Linkedin or Portfolio URL"
      type="url"
      value={formData.portfolio}
      onChange={handleChange}
      inputProps={{ maxLength: 50 }}
      error={formData.portfolio.length > 50}
      size="small"
      fullWidth
    />
    <TextField
      name="aboutMe"
      label="About Me"
      multiline
      rows={4}
      value={formData.aboutMe}
      onChange={handleChange}
      inputProps={{ maxLength: 1000 }}
      error={formData.aboutMe.length > 1000}
      fullWidth
    />
  </div>
);

export default ProfileEditionForm;
