import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import HomePage from "./views/HomePage";
import CompanyDescriptionPage from "./views/CompanyDescriptionPage";
import AllOpeningsPage from "./views/AllOpeningsPage/AllOpeningsPage";
import JobDescriptionPage from "./views/JobDescriptionPage";
import ApplyJobPage from "./views/ApplyJobPage/ApplyJobPage";
import HelpPage from "./views/HelpPage";
import ReferralPage from "./views/ReferralPage";
import { TelegramProvider } from "./utils/Telegram.Provider";
import { CustomThemeProvider } from "./utils/Theme.Provider";
import NavigationBar from "./components/NavigationBar/NavigationBar";
import { isValidObjectId } from "./utils/isValidId";
import ProfilePage from "./views/ProfilePage/ProfilePage";

const RedirectHandler = ({
  onRedirectComplete,
}: {
  onRedirectComplete: () => void;
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tgInitParam = searchParams.get("tgWebAppStartParam");

  React.useEffect(() => {
    if (tgInitParam) {
      const [prefix, id] = tgInitParam.split("_");

      if (id && !isValidObjectId(id)) {
        navigate("/"); // Redirect to home if the ID is invalid
        onRedirectComplete(); // Mark redirection as complete
        return;
      }

      // Handle redirection based on the prefix
      switch (prefix) {
        case "CMP":
          navigate(`/companies/${id}`);
          break;
        case "OPN":
          navigate(`/openings/${id}`);
          break;
        case "APP":
          navigate(`/openings/${id}/apply`);
          break;
        case "HELP":
          navigate("/help");
          break;
        default:
          navigate("/");
          break;
      }
    }
    onRedirectComplete();
  }, [navigate, tgInitParam, onRedirectComplete]);

  return null;
};

const App = () => {
  const [redirecting, setRedirecting] = React.useState(true);

  const handleRedirectComplete = () => {
    setRedirecting(false);
  };

  return (
    <TelegramProvider>
      <BrowserRouter>
        <CustomThemeProvider>
          <>
            <RedirectHandler onRedirectComplete={handleRedirectComplete} />
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route
                path="/companies/:companyId"
                element={<CompanyDescriptionPage />}
              />
              <Route path="/openings" element={<AllOpeningsPage />} />
              <Route
                path="/openings/:openingId"
                element={<JobDescriptionPage />}
              />
              <Route
                path="/openings/:openingId/apply"
                element={<ApplyJobPage />}
              />
              <Route path="/help" element={<HelpPage />} />
              <Route path="/referral" element={<ReferralPage />} />
              <Route path="/profile" element={<ProfilePage />} />
            </Routes>
            {!redirecting && <NavigationBar />}
          </>
        </CustomThemeProvider>
      </BrowserRouter>
    </TelegramProvider>
  );
};

export default App;
