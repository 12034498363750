export const OPENING_ROLES = [
  { label: "Customer Support", value: "customerSupport" },
  { label: "People & HR", value: "peopleHr" },
  { label: "Engineering", value: "engineering" },
  { label: "Operations", value: "operations" },
  { label: "Marketing", value: "marketing" },
  { label: "Finance", value: "finance" },
  { label: "Product", value: "product" },
  { label: "Design", value: "design" },
  { label: "Legal", value: "legal" },
] as const;

export type OpeningRoleValue = (typeof OPENING_ROLES)[number]["value"];
