import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import JobCard from "../../components/JobCard/JobCard";
import { makeStyles } from "@mui/styles";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
// import DebouncedInput from "../../components/DebouncedInput/DebouncedInput";
import FilterDrawer, {
  Filters,
  INITIAL_FILTERS,
} from "./components/FiltersDrawer";
import useFetchOpenings, { Opening } from "./hooks/useFetchOpenings";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";

const useStyles = makeStyles({
  header: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "16px",
  },
  filtersContainer: {
    position: "sticky",
    top: "0",
    zIndex: 1000,
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingTop: "16px",
    paddingBottom: "16px",
    backgroundColor: "#3B0742",
  },
  filters: {
    backgroundColor: "#121212",
    border: "0.5px solid #7A7A7A",
    borderRadius: "24px",
    height: "36px",
    display: "flex",
    gap: "4px",
    alignItems: "center",
    paddingLeft: "4px",
    paddingRight: "4px",
    paddingTop: "2px",
    paddingBottom: "2px",
  },
  searchInput: {
    width: "100%",
    height: "100%",
    backgroundColor: "inherit",
    color: "inherit",
    fontSize: "16px",
    border: "none",
    outline: "none",
    "&:focus": {
      outline: "none",
    },
  },
  boardContent: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    padding: "16px",
  },
  container: {
    paddingTop: "24px",
  },
});

function AllOpeningsPage() {
  const classes = useStyles();
  const [filters, setFilters] = React.useState<Filters>(INITIAL_FILTERS);
  const [open, setOpen] = React.useState<boolean>(false);

  // const setQueryString = React.useCallback((value: string) => {
  //   setFilters((previous) => ({ ...previous, query: value }));
  // }, []);

  const { openings, isLoading } = useFetchOpenings(filters);

  return (
    <Box className={classes.container}>
      <Box>
        <Box className={classes.header}>
          <img src={"assets/images/listingheader.png"} alt={"header"} />
        </Box>
        <Box className={classes.filtersContainer}>
          <Box className={classes.filters}>
            <SearchIcon />
            {/* <DebouncedInput
              className={classes.searchInput}
              placeholder={"Search ..."}
              value={filters.query}
              onChange={setQueryString}
            /> */}
            <Typography sx={{ width: "100%", color: "#7A7A7A !important" }}>
              Search by category
            </Typography>
            <IconButton color="inherit" onClick={() => setOpen(true)}>
              <TuneIcon />
            </IconButton>
          </Box>
        </Box>
        <BoardContent
          className={classes.boardContent}
          isLoading={isLoading}
          openings={openings}
        />
      </Box>
      <FilterDrawer
        open={open}
        onClose={() => setOpen(false)}
        filters={filters}
        setFilters={setFilters}
      />
    </Box>
  );
}

const BoardContent = ({
  isLoading,
  openings,
  className,
}: {
  isLoading: boolean;
  openings: Opening[];
  className?: string;
}) => {
  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <Box className={className}>
      {openings.map((opening) => (
        <JobCard key={opening.id} opening={opening} />
      ))}
    </Box>
  );
};

export default AllOpeningsPage;
