import { createTheme } from "@mui/material/styles";

const lightTheme = createTheme({
  palette: {
    mode: "light",
    background: {
      default: "#ffffff",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          color: "#FFFFFF",
          background: "#3B0742",
          minHeight: "100%",
          paddingBottom: 66,
          overflowY: "scroll",
          overflowX: "hidden",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            backgroundColor: "#2B2B2B",
            borderRadius: "24px",
            color: "#FFFFFF",
            "& fieldset": {
              borderColor: "#6A27A7",
            },
            "&:hover fieldset": {
              borderColor: "#B400FF",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#6A27A7",
            },
          },
          "& .MuiInputLabel-root": {
            color: "#FFFFFF",
            "&.Mui-focused": {
              color: "#FFFFFF",
            },
          },
          "& .MuiFormHelperText-root": {
            color: "#FFFFFF",
          },
        },
      },
    },
  },
});

export const getTheme = () => lightTheme;
