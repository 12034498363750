import React from "react";

export interface Profile {
  firstName: string;
  lastName: string;
  xProfile?: string;
  portfolio?: string;
  aboutMe?: string;
  hasResume?: boolean;
}

export const useGetProfile = (telegramId?: number) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [profile, setProfile] = React.useState<Profile>();
  const [error, setError] = React.useState<string>();

  React.useEffect(() => {
    const getProfile = async () => {
      setIsLoading(true);
      setError(undefined);

      if (!telegramId) {
        setProfile(undefined);
        setIsLoading(false);
        return;
      }

      try {
        let url = `${process.env.REACT_APP_SERVER_URL}/api/profiles/telegram/${telegramId}`;

        const response = await fetch(url);

        if (!response.ok) {
          throw new Error(`Error fetching Profile: ${response.statusText}`);
        }

        const data = await response.json();

        setProfile(data);
      } catch (err: any) {
        setError(err.message || "An unexpected error occurred.");
      } finally {
        setIsLoading(false);
      }
    };

    getProfile();
  }, [telegramId]);

  return { isLoading, profile, error };
};
