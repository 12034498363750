import React from "react";
import { useTelegram } from "../utils/Telegram.Provider";
import AnimatedSuccess from "../components/AnimatedSuccess";
import { Box, Button, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import CustomBox from "../components/Box/Box";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  header: {
    paddingBottom: "32px",
    background: "linear-gradient(to right, #952A98, #660872)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  svg: {
    paddingBottom: "15%",
  },
});

function ApplicationSuccess() {
  const classes = useStyles();
  const webApp = useTelegram();

  const closeMiniApp = React.useCallback(() => {
    webApp?.close();
  }, [webApp]);

  return (
    <CustomBox
      sx={{
        height: "100%",
        marginX: "8px",
        backgroundColor: "#150028",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        paddingTop: "25%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          paddingLeft: "2%",
          paddingRight: "2%",
          margin: 2,
          textAlign: "center",
        }}
      >
        <Box>
          <Typography
            className={classes.header}
            variant="h5"
            sx={{
              marginBottom: 2,
              fontWeight: "bold",
            }}
          >
            Your application was submitted successfully!
          </Typography>
          <Box className={classes.svg}>
            <AnimatedSuccess />
          </Box>
          <Typography
            sx={{
              marginBottom: 2,
              fontSize: "17px",
            }}
          >
            The company will reach out directly to you if they proceed with your
            application.
          </Typography>
          <Typography
            sx={{
              marginBottom: 2,
              fontSize: "17px",
            }}
          >
            In the meantime, do follow our X and join our Web3 Jobboard! 🤝
          </Typography>
          <Link
            href="http://x.com/unicastai"
            underline="hover"
            sx={{
              marginBottom: 1,
              fontSize: "17px",
            }}
          >
            x.com/unicast
          </Link>
          <Link
            href="http://x.com/unicastai"
            underline="hover"
            sx={{
              marginBottom: 1,
              fontSize: "17px",
            }}
          >
            t.me/unicastjobs
          </Link>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          onClick={closeMiniApp}
          sx={{
            width: "90%",
            borderRadius: "24px",
            backgroundColor: "#61157D",
            color: "#FFFFFF",
            marginBottom: 2,
            "&:hover": {
              backgroundColor: "#61157D",
            },
            "&:active": {
              backgroundColor: "#61157D",
            },
          }}
        >
          Close
        </Button>
      </Box>
    </CustomBox>
  );
}

export default ApplicationSuccess;
