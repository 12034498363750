import React from "react";
import ApplicationSuccess from "../ApplicationSuccess";
import { JobOpening, fetchOpening } from "../../api/opening.fetcher";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import NotFound from "../../components/NotFound/NotFound";
import { ApplicationForm } from "./components/ApplicationForm";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  baseContainer: {
    paddingTop: "16px",
    paddingLeft: "8px",
    paddingRight: "8px",
    paddingBottom: "16px",
    width: "100%",
    minHeight: "calc(100vh - 66px)",
  },
  applyContainer: {
    backgroundColor: "#121212",
  },
});

const ApplyJobPage = () => {
  const { openingId } = useParams<{ openingId: string }>();
  const [isSuccess, setIsSuccess] = React.useState<boolean>(false);
  const [opening, setOpening] = React.useState<JobOpening>();
  const [loading, setLoading] = React.useState<boolean>(true);

  const classes = useStyles();

  React.useEffect(() => {
    if (!openingId) {
      return;
    }
    fetchOpening({
      jobId: openingId,
      onSuccess: (data) => {
        setOpening(data);
        setLoading(false);
      },
      onError: () => setLoading(false),
    });
  }, [openingId]);

  if (loading)
    return (
      <Box className={classes.baseContainer}>
        <LoadingIndicator />
      </Box>
    );
  if (!opening)
    return (
      <Box className={classes.baseContainer}>
        <NotFound />
      </Box>
    );
  if (isSuccess)
    return (
      <Box className={classes.baseContainer}>
        <ApplicationSuccess />
      </Box>
    );

  return (
    <Box className={`${classes.baseContainer} ${classes.applyContainer}`}>
      <ApplicationForm
        jobOpening={opening}
        onSuccess={() => setIsSuccess(true)}
        onError={() => {}}
      />
    </Box>
  );
};

export default ApplyJobPage;
