import React from "react";
import { Box, Divider, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTelegram } from "../../utils/Telegram.Provider";
import EditIcon from "@mui/icons-material/Edit";
import { useGetProfile } from "./hooks/useGetProfile";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import ProfileEditionForm from "./components/ProfileEditionForm";

const useStyles = makeStyles({
  headerImage: {
    width: "100%",
    height: "auto",
  },
  profilePicture: {
    width: "150px",
    height: "150px",
    borderRadius: "50%",
  },
  profileContainer: {
    width: "max-content",
    position: "absolute",
    bottom: 0,
    left: "50%",
    transform: "translateX(-50%)",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    justifyContent: "center",
    alignItems: "center",
  },
  detailsContainer: {
    padding: "16px",
  },
  detailsCard: {
    padding: "16px",
    backgroundColor: "#121212",
    color: "#FFFFFF",
    borderRadius: "24px",
  },
  detailsHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  details: {
    paddingTop: "8px",
    paddingBottom: "8px",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  divider: {
    backgroundColor: "white",
    height: "1px",
    marginTop: "16px !important",
    marginBottom: "16px !important",
    marginLeft: "12% !important",
    marginRight: "12% !important",
  },
  textHelper: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "16px",
    color: "#FFB6EC",
  },
});

function ProfilePage() {
  const classes = useStyles();
  const telegram = useTelegram();

  const [editionMode, setEditionMode] = React.useState<boolean>(false);

  const { profile, isLoading } = useGetProfile(
    // Remove checks
    telegram?.initDataUnsafe?.user?.id
  );

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (editionMode) {
    return (
      <ProfileEditionForm
        profile={profile}
        onCloseEdition={() => setEditionMode(false)}
      />
    );
  }

  // TODO: Fix image loading & layout shift
  return (
    <Box>
      <Box sx={{ position: "relative", display: "inline-block" }}>
        <img
          src="assets/images/profile.png"
          alt="Descriptive Alt Text"
          className={classes.headerImage}
        />
        <Box className={classes.profileContainer}>
          <img
            className={classes.profilePicture}
            src={
              telegram?.initDataUnsafe?.user?.photo_url ||
              "assets/images/defaultpp.png"
            }
            alt={"profile-picture"}
          />
          <Typography variant="h4">
            {telegram?.initDataUnsafe?.user?.username || "Create Your Profile"}
          </Typography>
        </Box>
      </Box>
      {!profile && (
        <Box className={classes.textHelper}>
          <Typography variant="body1" sx={{}}>
            Fill in your profile for one click apply
          </Typography>
        </Box>
      )}
      <Box className={classes.detailsContainer}>
        <Box className={classes.detailsCard}>
          <Box className={classes.detailsHeader}>
            <Typography variant="h6">Profile Information</Typography>
            <IconButton color="inherit" onClick={() => setEditionMode(true)}>
              <EditIcon fontSize="small" />
            </IconButton>
          </Box>
          <Divider className={classes.divider} />
          <Box className={classes.details}>
            <Box
              style={{
                display: "flex",
                gap: "96px",
                alignItems: "center",
              }}
            >
              <InformationBox
                label={"First Name"}
                value={profile?.firstName || ""}
              />
              <InformationBox
                label={"Last Name"}
                value={profile?.lastName || ""}
              />
            </Box>
            <InformationBox
              label={"X Profile"}
              value={profile?.xProfile || ""}
            />
            <InformationBox
              label={"Github, LinkedIn or Portfolio URL"}
              value={profile?.portfolio || ""}
            />
            <InformationBox label={"About Me"} value={profile?.aboutMe || ""} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const useHelperStyles = makeStyles({
  textBox: {
    display: "flex",
    flexDirection: "column",
    gap: "2px",
  },
  value: {
    color: "#AEAEAE",
  },
});

const InformationBox = ({ label, value }: { label: string; value: string }) => {
  const classes = useHelperStyles();
  return (
    <Box className={classes.textBox}>
      <Typography variant="body1">{label}</Typography>
      <Typography className={classes.value} variant="body1">
        {value}
      </Typography>
    </Box>
  );
};

export default ProfilePage;
