import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  container: {
    paddingLeft: "8px",
    paddingRight: "8px",
    marginTop: "24px",
  },
  banner: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    textAlign: "center",
    marginTop: "48px",
  },
  actionBox: {
    textAlign: "center",
    borderRadius: "32px",
    backgroundColor: "#121212",
    marginTop: "32px",
  },
});

function ReferralPage() {
  const classes = useStyles();
  const [copied, setCopied] = React.useState<boolean>(false);

  const handleCopy = async () => {
    if (process.env.REACT_APP_MINIAPP_URL) {
      await navigator.clipboard.writeText(process.env.REACT_APP_MINIAPP_URL);
    }
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.actionBox}>
        <IconButton color="inherit" onClick={handleCopy}>
          <Typography variant="h4">
            {!copied ? "Get Miniapp Link" : "Copied to clipboard!"}
          </Typography>
        </IconButton>
      </Box>
      <Box className={classes.actionBox}>
        <IconButton color="inherit">
          <Typography variant="h4">Share Miniapp</Typography>
        </IconButton>
      </Box>
      <Box className={classes.banner}>
        <Typography variant="h4">Rewards & Bounties</Typography>
        <img src={"/assets/images/comingsoon.png"} alt="coming-soon" />
      </Box>
    </Box>
  );
}

export default ReferralPage;
