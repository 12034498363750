import ThemedButton from "../Custom/Button";
import ClearIcon from "@mui/icons-material/Clear";
import {
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";

export const FileUploadField = ({
  file,
  handleFileChange,
  clearFile,
  isResumeRequired,
}: {
  file: File | null;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  clearFile: () => void;
  isResumeRequired?: boolean;
}) => (
  <div>
    <input
      id="file-input"
      type="file"
      accept="application/pdf"
      style={{ display: "none" }}
      onChange={handleFileChange}
    />
    <TextField
      variant="outlined"
      InputProps={{
        readOnly: true,
        startAdornment: (
          <InputAdornment position="start">
            {file ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "4px",
                  alignItems: "center",
                  color: "#FFFFFF",
                  maxWidth: "250px",
                }}
              >
                <Typography
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {file.name}
                </Typography>
                <IconButton onClick={clearFile}>
                  <ClearIcon fontSize="small" sx={{ color: "#FFFFFF" }} />
                </IconButton>
              </div>
            ) : (
              <ThemedButton
                size="small"
                onClick={() => document.getElementById("file-input")!.click()}
              >
                {isResumeRequired ? "Attach resume *" : "Attach resume"}
              </ThemedButton>
            )}
          </InputAdornment>
        ),
      }}
      helperText="Max size 4MB - PDF Only"
      error={
        !!file &&
        (file.size > 4 * 1024 * 1024 || file.type !== "application/pdf")
      }
      size="small"
      fullWidth
      required
    />
  </div>
);
