import { styled } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";

const CustomButton = styled(Button)(() => ({
  color: "#FFFFFF",
  fontSize: "16px",
  borderRadius: "8px",
  textTransform: "none",
  boxShadow: "none",
}));

const ThemedButton = (props: ButtonProps) => {
  return <CustomButton {...props} />;
};

export default ThemedButton;
