import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import WorkIcon from "@mui/icons-material/Work";
import PersonIcon from "@mui/icons-material/Person";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import IconButton from "@mui/material/IconButton";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  container: {
    bottom: 0,
    position: "fixed",
    width: "100%",
    backgroundColor: "#3B0742",
    zIndex: 1000,
    paddingTop: "2px",
    paddingLeft: "4px",
    paddingRight: "4px",
  },
  navigationBar: {
    backgroundColor: "#121212",
    borderRadius: "12px",
    border: "0.5px solid #FFFFFF",
    width: "100%",
    color: "white",
    marginBottom: "4px",
    height: "60px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  selectedTab: {
    border: "1px solid #FFFFFF",
    borderRadius: "8px",
  },
});

const DEFAULT_COLOR = "rgba(255, 255, 255, 0.4)";
const SELECTED_COLOR = "rgba(255, 255, 255)";

function NavigationBar() {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  // Function to check if the current path matches the button's path
  const isSelected = (path: string) => location.pathname === path;

  return (
    <Box className={classes.container}>
      <Box className={classes.navigationBar}>
        <IconButton
          sx={
            isSelected("/")
              ? {
                  color: SELECTED_COLOR,
                  border: "1px solid #FFFFFF",
                }
              : { color: DEFAULT_COLOR }
          }
          onClick={() => navigate("/")}
        >
          <HomeIcon fontSize="large" />
        </IconButton>
        <IconButton
          sx={
            isSelected("/openings")
              ? {
                  color: SELECTED_COLOR,
                  border: "1px solid #FFFFFF",
                }
              : { color: DEFAULT_COLOR }
          }
          onClick={() => navigate("/openings")}
        >
          <WorkIcon fontSize="large" />
        </IconButton>
        <IconButton
          sx={
            isSelected("/referral")
              ? {
                  color: SELECTED_COLOR,
                  border: "1px solid #FFFFFF",
                }
              : { color: DEFAULT_COLOR }
          }
          onClick={() => navigate("/referral")}
        >
          <Diversity3Icon fontSize="large" />
        </IconButton>
        <IconButton
          sx={
            isSelected("/profile")
              ? {
                  color: SELECTED_COLOR,
                  border: "1px solid #FFFFFF",
                }
              : { color: DEFAULT_COLOR }
          }
          onClick={() => navigate("/profile")}
        >
          <PersonIcon fontSize="large" />
        </IconButton>
      </Box>
    </Box>
  );
}

export default NavigationBar;
