import React from "react";
import { Typography, Divider } from "@mui/material";
import Markdown from "react-markdown";
import { makeStyles } from "@mui/styles";
import { Company, fetchCompany } from "../api/company.fetcher";
import LoadingIndicator from "../components/LoadingIndicator/LoadingIndicator";
import NotFound from "../components/NotFound/NotFound";
import { useParams } from "react-router-dom";

const useStyles = makeStyles({
  container: {
    paddingLeft: "2%",
    paddingRight: "2%",
    paddingTop: "8px",
  },
  title: {
    textAlign: "center",
    paddingBottom: "8px",
  },
  image: {
    width: "70vw",
    height: "auto",
  },
  imageContainer: {
    textAlign: "center",
  },
  description: {
    paddingBottom: "8px",
    paddingTop: "8px",
    paddingLeft: "4px",
    paddingRight: "4px",
  },
});

const CompanyDescriptionPage = () => {
  const classes = useStyles();

  const { companyId } = useParams<{ companyId: string }>();

  const [company, setCompany] = React.useState<Company>();
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (!companyId) {
      return;
    }

    fetchCompany({
      companyId,
      onSuccess: (d) => {
        setCompany(d);
        setLoading(false);
      },
      onError: (e) => {
        console.log(e);
        setLoading(false);
      },
    });
  }, [companyId]);

  if (loading) {
    return <LoadingIndicator />;
  }

  if (!company && !loading) {
    return <NotFound />;
  }

  return (
    <div className={classes.container}>
      <div>
        <Typography className={classes.title} variant="h3">
          {company!.name}
        </Typography>
        <div className={classes.imageContainer}>
          <img
            src={company!.bannerPath}
            alt={`${company!.name} banner`}
            className={classes.image}
          />
        </div>
      </div>

      <Divider />
      <div className={classes.description}>
        <Markdown>{company!.description}</Markdown>
      </div>

      <Divider />
    </div>
  );
};

export default CompanyDescriptionPage;
