export interface Tip {
  id: string;
  title: string;
  description: string;
  imageUrl: string;
}

interface FetchOpts {
  onSuccess: (data: Tip) => void;
  onError: (err: Error) => void;
}

export const fetchTipOfTheDay = ({ onSuccess, onError }: FetchOpts) => {
  fetch(`${process.env.REACT_APP_SERVER_URL}/api/tips/tip-of-today`)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      return response.json();
    })
    .then((data) => {
      onSuccess(data);
    })
    .catch((error) => {
      onError(error);
    });
};
