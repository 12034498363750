import React from "react";
import { Filters } from "../components/FiltersDrawer";

export interface Opening {
  id: string;
  company: {
    id: string;
    name: string;
    summary: string;
    bannerPath: string;
  };
  title: string;
  positionType:
    | "partTime"
    | "fullTime"
    | "contract"
    | "freelance"
    | "internship";
  jobLocation: "onSite" | "remote" | "onSiteOrRemote";
  summary: string;
}

const useFetchOpenings = (filters: Filters) => {
  const [openings, setOpenings] = React.useState<Opening[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  React.useEffect(() => {
    const fetchOpenings = async () => {
      setIsLoading(true);
      setError(null);

      try {
        let url = `${process.env.REACT_APP_SERVER_URL}/api/openings`;

        const queryParams = new URLSearchParams();
        if (filters.query) {
          queryParams.append("q", filters.query);
        }

        filters.roles.forEach((role) => {
          queryParams.append("roles[]", role);
        });

        if (queryParams.toString()) {
          url += `?${queryParams.toString()}`;
        }

        const response = await fetch(url);

        if (!response.ok) {
          throw new Error(`Error fetching openings: ${response.statusText}`);
        }

        const data = await response.json();

        setOpenings(data);
      } catch (err: any) {
        setError(err.message || "An unexpected error occurred.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchOpenings();
  }, [filters]);

  return { openings, isLoading, error };
};

export default useFetchOpenings;
